
import ProductoService from "../../Services/ProductoService";
import FECHAS from "@/Constantes/Fecha";
export default {
  name: "ProductoGestor",
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Artículos" }],
      registroOpciones: FECHAS.FECHAS_FILTRO_REGISTRO,
      filtros: {
        busqueda: "",
        registro: "",
        fechaInicio: "",
      },
      categorias: [],
      subcategorias: [],
      marcas: [],
      unidadesMedida: [],
      productos: [],
      columnas: [
        {
          name: "folio",
          header: "#Artículo",
          align: "center",
          field: "folio_articulo",
          minWidth: 80,
          width: 113,
          templateName: "folio",
          routeName: "productosDetalle",
          rutaId: "articulo_id",
        },
        {
          name: "titulo",
          header: "Título",
          align: "left",
          field: "titulo",
          minWidth: 160,
          width: 180,
        },
        {
          name: "descripcion",
          header: "Descripción",
          align: "left",
          field: "descripcion",
          minWidth: 200,
          width: 320,
        },
        {
          name: "categoria",
          header: "Categoría",
          align: "left",
          field: "categoria_nombre",
          minWidth: 160,
          width: 200,
        },
        {
          name: "subcategoria",
          header: "Subcategoría",
          align: "left",
          field: "subcategoria_nombre",
          minWidth: 140,
          width: 180,
        },
        {
          name: "marca",
          header: "Marca",
          align: "left",
          field: "marca_nombre",
          minWidth: 140,
          width: 148,
        },
        {
          name: "valorEstimado",
          header: "Valor estimado",
          align: "right",
          field: "valor_estimado_minimo",
          minWidth: 120,
          width: 140,
          formatear: (val) => this.accounting.formatMoney(val),
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          field: "status",
          statusName: "status_nombre",
          minWidth: 130,
          width: 147,
          templateName: "status",
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 120,
          width: 155,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "registroAutor",
          header: "Autor",
          align: "left",
          field: "registro_autor",
          minWidth: 130,
          width: 160,
        },
      ],
      accionLimpiar: false,
      modalAgregar: false,
      agregarProductoObj: {
        titulo: "",
        descripcion: "",
        categoriaId: "",
        subcategoriaId: "",
        marcaId: "",
        unidadMedidaId: "",
        valorEstimadoMinimo: "",
      },
    };
  },
  mounted() {
    this.cargaInicial();
  },
  computed: {},
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerConcentradoOpciones(false);
      await this.obtenerProductos(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        fechaInicio: query.fi || "",
        ordenar: "folio_desc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        fi: this.filtros.fechaInicio,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerProductos(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await ProductoService.listarGestor(this.filtros)
        .then((resp) => {
          let productos = resp;
          this.productos = productos.map((pro) => {
            return {
              ...pro,
              registro_fecha: new Date(pro.registro_fecha),
            };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      await ProductoService.obtenerConcentradoOpciones()
        .then((resp) => {
          this.categorias = resp.categorias;
          this.marcas = resp.marcas;
          this.unidadesMedida = resp.unidadesMedida;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    seleccionarSubcategorias(categoriaId) {
      this.agregarProductoObj.subcategoriaId = "";
      let categoria = this.categorias.find(
        (cat) => cat.categoria_id == categoriaId
      );
      this.subcategorias = categoria.subcategorias;
    },
    abrirModalAgregar() {
      this.modalAgregar = true;
    },
    cerrarModalAgregar() {
      this.agregarProductoObj = {
        titulo: "",
        descripcion: "",
        categoriaNombre: "",
        categoriaId: "",
        subcategoriaId: "",
        subcategoriaNombre: "",
        marcaId: "",
        unidadMedidaId: "",
        valorEstimadoMinimo: "",
      };
      this.modalAgregar = false;
    },
    abrirModalFiltros() {
      console.log("filtros");
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        fechaInicio: "",
        ordenar: "folio_desc",
      };
      this.obtenerProductos();
      this.accionLimpiar = true;
    },
    async agregarProducto(loader = true) {
      if (loader) this.loader(true);
      await ProductoService.agregarProducto(this.agregarProductoObj)
        .then((resp) => {
          this.cerrarModalAgregar();
          this.obtenerProductos(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
  },
};
